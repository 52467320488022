@import 'fonts.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --roboto: 'Roboto', sans-serif;
  --inter: 'Inter', sans-serif;
  --mono: 'Roboto Mono', monospace;
}

button {
  @apply focus:outline-none;
}

.custom-time-picker .bp3-timepicker-input-row,
.custom-time-picker .bp3-timepicker-input-row:focus {
  box-shadow: none;
  padding: 0;
  line-height: normal;
  height: fit-content;
  outline: none !important;
  --tw-ring-color: transparent !important;
}
.custom-time-picker input.bp3-timepicker-input,
.custom-time-picker input.bp3-timepicker-input:focus {
  box-shadow: none;
  padding: 0;
  line-height: normal;
  outline: none !important;
  --tw-ring-color: transparent !important;
}
